import React from 'react'
import { graphql } from 'gatsby';
// import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";

import BlockContent from '@sanity/block-content-to-react';


import styled from 'styled-components';
// import {device} from '../styles/Breakpoints'

import Header from '../components/Header';
import CornerIcons from '../components/CornerIcons';
import Cursor from '../components/Cursor';




const SignUpStyles = styled.div`
       main {
         width: 100%;
         min-height: 100vh;
         display: flex;
         flex-direction: column;
         justify-content: center;
         align-items: center;
         padding: 112px 0;
         .wrapper {
           max-width: 500px;
           padding: 0 48px;
           text-align: center;
           .description {
             margin-bottom: 32px;
           }
         }
         .error-message {
             font-size: 12px;
             margin: 12px 0;
             color: red;
          }
       }
`

class ResourcesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
          cornerIconData: this.props.data.cornerIconData.nodes[0],
          siteSettingsData: this.props.data.siteSettings.nodes[0],
          resourcesPageData: this.props.data.resourcesPageData.nodes[0],
          hasBeenSubmitted: false,
          password: ''
        };
    }
  
    componentDidMount() {
      // console.log('props', this.props)
    }

    handleSubmit = (e) => {
      e.preventDefault();
      if (this.state.password !== '') {
        this.setState({ hasBeenSubmitted: true })
      }
      
    }

    handlePasswordChange = (e) => {
      this.setState({ password: e.target.value })
    }

    render() {

      const data = this.state.resourcesPageData;
      const siteSettings = this.state.siteSettingsData;
      const cornerIcondata = this.state.cornerIconData
    
        return (
          <>
            <Helmet title="Resources | Mimi Casting"/>
            <SignUpStyles >
              <Header data={siteSettings} />
              <CornerIcons data={cornerIcondata} siteSettings={siteSettings}/>
              <Cursor 
                staticText={siteSettings.cursor_static}
                staticTextColor={siteSettings.cursor_static_color.hex}
                cursorSparkle={siteSettings.cursor_sparkle}
              />
              <main>
                <div className="wrapper">
                  <h2>{ data.page_heading }</h2>
                  <BlockContent 
                    className="description"
                    blocks={data._rawDescription}
                    renderContainerOnSingleChild={true}
                  />
                </div>
                <form onSubmit={this.handleSubmit}>
                  <div className="field">
                    <label for="password">Password</label>
                    <input type="password" id="password" value={ this.state.password } onChange={ this.handlePasswordChange }/>
                  </div>
                  {
                    this.state.hasBeenSubmitted && 
                    <div className="error-message">*Incorrect Password</div>
                  }
                  <button className="button">Submit</button>
                </form>
              </main>
            </SignUpStyles>
          </>
        )
    }

}

export default ResourcesPage


export const query = graphql`
query {
    cornerIconData: allSanityCornerIcons {
        nodes {
          corner_icon__top_left_url
          corner_icon__top_right_url
          corner_icon__bottom_left_url
          corner_icon__bottom_right_url
          corner_icon__top_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__top_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_left_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
          corner_icon__bottom_right_image {
            asset {
              gatsbyImageData(width: 156, layout: CONSTRAINED)
            }
          }
        }
    }
  resourcesPageData: allSanityResourcesPage {
    nodes {
      _rawDescription
      page_heading
    }
  }
  siteSettings: allSanitySiteSettings {
    nodes {
      logo {
        asset {
          extension
          gatsbyImageData(width: 300, layout: CONSTRAINED)
          url
        }
      }
      site_hover_color {
        rgb {
          r
          g
          b
          a
        }
      }
      cursor_sparkle
      cursor_static
      cursor_static_color {
        hex
      }
    }
  }
}
`